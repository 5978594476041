import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../core/classes/menu-item';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  menuVisible:boolean=false; 
  contactmeVisible:boolean=false; 

  menuitems:MenuItem[] = [
                            {
                              url:'traditioneel-stukadoorswerk',
                              naam:'Traditioneel stukadoorswerk',
                              subitems: [
                                {
                                  url:'raapwerk',
                                  naam:'Raapwerk'
                                },
                                {
                                  url:'sierpleister',
                                  naam:'Sierpleister'
                                },
                                {
                                  url:'pleisterwerk',
                                  naam:'Pleisterwerk'
                                },
                                {
                                  url:'schuurwerk',
                                  naam:'Schuurwerk'
                                }
                              ]
                            },
                            {
                              url:'nieuwbouw',
                              naam:'Nieuwbouw',
                              subitems:[
                                {
                                  url:'spackspuitwerk',
                                  naam:'Spackspuitwerk'
                                },
                                {
                                  url:'nieuwbouw-airlessen',
                                  naam:'Nieuwbouw airlessen'
                                }
                              ]
                            
                            },
                            {url:'kalei',naam:'Kalei'},
                            {url:'#',image:'../../../assets/logo.png'},
                            {url:'lijst-decoratief-stucwerk',naam:'Lijst/decoratief stucwerk'},
                            {url:'ventilatorverhuur',naam:'Ventilatorverhuur'},
                            {url:'contact',naam:'Contact'}
                          ];
  constructor() {
   
   }

  ngOnInit() {
  }
  toggleMenu(){
    if(this.menuVisible){
     this.menuVisible = false;
    }else{
      this.menuVisible=true;
    }
  }
  toggleContactMe(){
    if(this.contactmeVisible){
     this.contactmeVisible = false;
    }else{
      this.contactmeVisible=true;
    }
  }
}
